/* You can add global styles to this file, and also import other style files */
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "~@psv/psv-player/dist/psv-player.min.css";
@import "themes.scss";
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-balham.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

@import "@nebular/theme/styles/globals";

@include nb-install() {
  @include nb-theme-global();
}

:root {
  font-size: max(9.6px, 0.88vw);
}

body {
  background-color: #f2f2f5 !important;
}

.toast-title {
  margin-bottom: 0.4rem !important;
  font-size: 1.2rem !important;
}

.toast-message {
  font-size: 1rem !important;
}

.toast-error {
  font-family: "OpenSansRegular", system-ui, -apple-system, "Open Sans",
    "Helvetica Neue", sans-serif;
  background-image: none !important;
  background-color: #db2c66 !important;
  box-shadow: 0 0 2px #999999 !important;

  &:hover {
    box-shadow: 0 0 2px #000000 !important;
  }
}

.toast-container .ngx-toastr {
  width: 390px !important;
  padding: 15px 15px 15px 20px !important;
}

// .flex-card {
//   flex: 1;
//   padding: 0 0.5%;
// }

// .flex-card nb-card {
//   height: 100%;
// }

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px #737373;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #737373;
  border-radius: 5px;
}

.flex-card {
  flex: 1;
  padding: 0 1.5%;
}

.spacing {
  padding: 0 3%;
}

.flex-card nb-card {
  height: 90%;
}

.column-class {
  flex: 1;
  width: inherit;
}

.column-class .flex-column {
  margin: 0 !important;
}

.h-inherit {
  height: inherit;
}

/* ==========================================================================
Fonts
========================================================================== */

@font-face {
  font-family: "OpenSansRegular";
  src: url("/content/font/Open_Sans/OpenSans-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Tungsten";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/Tungsten/Tungsten-Medium.ttf");
}

@font-face {
  font-family: "OpenSansBold";
  src: url("/content/font/Open_Sans/OpenSans-Bold.ttf");
  font-display: swap;
}

cortex-learn {
  h1,
  h2,
  h3,
  h4 {
    font-weight: 300;
  }

  a {
    color: #533f03;
    font-weight: bold;
  }

  a:hover {
    color: #533f03;
  }

  /* override hover color for dropdown-item forced by bootstrap to all a:not([href]):not([tabindex]) elements in _reboot.scss */
  a:not([href]):not([tabindex]):hover.dropdown-item {
    color: $dropdown-link-hover-color;
  }

  /* override .dropdown-item.active background-color on hover */
  .dropdown-item.active:hover {
    background-color: mix(
      $dropdown-link-hover-bg,
      $dropdown-link-active-bg,
      50%
    );
  }

  a:hover {
    /* make sure browsers use the pointer cursor for anchors, even with no href */
    cursor: pointer;
  }

  .dropdown-item:hover {
    color: $dropdown-link-hover-color;
  }

  /* ==========================================================================
Generic styles
========================================================================== */

  /* Error highlight on input fields */
  .ng-valid[required],
  .ng-valid.required {
    border-left: 5px solid green;
  }

  .ng-invalid:not(form) {
    border-left: 5px solid red;
  }

  /* other generic styles */

  .error {
    color: white;
    background-color: #ff4c61;
  }

  .pad {
    padding: 10px;
  }

  .w-40 {
    width: 40% !important;
  }

  .w-60 {
    width: 60% !important;
  }

  .break {
    white-space: normal;
    word-break: break-all;
  }

  .readonly {
    background-color: nb-theme(background-basic-color-3);
    opacity: 1;
  }

  .footer {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }

  .hand {
    cursor: pointer;
  }

  .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .nav,
  .pagination,
  .carousel,
  .panel-title a {
    cursor: pointer;
  }

  .thread-dump-modal-lock {
    max-width: 450px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .home {
    padding: 0 !important;
  }

  .window-size {
    width: 27vw !important;
    max-width: 27vw !important;
    box-shadow: 0 0 8px rgba(248, 243, 243, 0.904);
  }

  .window-size nb-card-header {
    padding-left: 1vw;
    padding-right: 0.6vw;
    padding-bottom: 0.7vh;
    padding-top: 0.7vh;
  }

  .window-size nb-card-body {
    padding-top: 2vh;
    padding-bottom: 1vh;
    padding-left: 1vw;
    padding-right: 1vw;
  }

  .ctx2-page-header {
    font-style: normal;
    font-weight: bold;
    font-size: 1.8rem;
    color: nb-theme(primary-color);
  }

  .ctx2-page-subheader {
    color: nb-theme(text-basic-color);
    font-style: normal;
    font-weight: bold;
    font-size: 1.2rem;
  }

  .card {
    box-shadow: nb-theme(alternate-card-shadow);
    border-radius: nb-theme(card-border-radius);
    color: nb-theme(card-text-color);
    font-family: "OpenSansRegular", sans-serif;
    font-size: nb-theme(card-text-font-size);
    font-weight: nb-theme(card-text-font-weight);
    line-height: nb-theme(card-text-line-height);
    margin-bottom: nb-theme(card-margin-bottom);
    background-color: nb-theme(card-background-color);
    border: nb-theme(card-border-width) nb-theme(card-border-style)
      nb-theme(card-border-color);
  }

  .ctx2-card-title {
    color: nb-theme(primary-color);
    outline: none;
  }

  .ctx2-card-subtitle {
    color: nb-theme(text-hint-color);
  }

  .btn-outline-primary {
    background-color: nb-theme(button-outline-primary-background-color);
    border-color: nb-theme(button-outline-primary-border-color);
    color: nb-theme(button-outline-primary-text-color);
  }

  .btn-primary {
    background-color: nb-theme(button-filled-primary-background-color);
    border-color: nb-theme(button-filled-primary-border-color);
    color: nb-theme(button-filled-primary-text-color);
  }

  .ctx2-half {
    float: left;
    width: 48%;
    padding-left: 1vw;
    box-sizing: border-box;
  }

  .copy-right-text-color {
    color: nb-theme(tabset-divider-color);
  }

  .ctx2-text {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .module-header-title {
    font-size: 1.1rem !important;
    line-height: 2rem !important;
  }

  nb-spinner {
    z-index: 999 !important;
  }

  @media (max-width: 767px) {
    .ctx2-half {
      float: none;
      width: 100%;
      box-sizing: border-box;
      margin: 15px 0px;
    }

    .text-left {
      text-align: center;
      width: 100%;
      float: none;
      margin-bottom: 2vh;
    }

    .text-right {
      text-align: center;
      width: 100%;
      float: none;
      margin-bottom: 2vh;
    }
  }

  .training-bank-font-sizes-header {
    font-size: 0.8rem !important;
  }

  .training-bank-font-sizes-data {
    font-size: 0.7rem !important;
  }

  .training-bank-font-sizes-note {
    font-size: 0.65rem !important;
  }

  *:focus {
    outline: 0;
  }

  .button-styles {
    font-size: 1.125rem !important;
    line-height: 1.5625rem !important;
  }

  .button-small-style {
    padding: 0.4rem 0.8rem !important;
    font-size: 0.95rem !important;
  }

  .cl-no-data {
    font-size: 1rem;
  }
}

.button-styles {
  font-size: 1.125rem !important;
  line-height: 1.5625rem !important;
}

.button-small-style {
  padding: 0.5rem 0.8rem !important;
  font-size: 1rem !important;
}

.cdk-overlay-container {
  z-index: 9000 !important;
}
